import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import CaseReportForm from "./pages/crf/CaseReportForm";
import { CaseReportList } from "./pages/crf/CaseReportList";
export default function BasePage({ auth }) {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect
  // const userObj = useSelector(({ user }) => user.user, shallowEqual);
  const MainPage = lazy(() => import("./pages/MainPage"));
  const UserMainPage = lazy(() => import("./pages/UserMainPage"));
  const UserListPage = lazy(() => import("./pages/user-list/UserListPage"));
  const PredictPage = lazy(() => import("./pages/predict/PredictPage"));
  const PredictHistoryPage = lazy(() => import("./pages/predict/PredictHistoryPage"));
  const PredictResultPage = lazy(() => import("./pages/predict/predictResult/PredictResultPage"));
  const PredictSurvey = lazy(() => import("./pages/predict/predictResult/PredictSurvey"));
  const FoodPatternPage = lazy(() => import("./pages/food-pattern/FoodPatternPage"));
  const FoodPatternResult = lazy(() => import("./pages/food-pattern/FoodPatternResult"));
  const FoodPatternSurvey = lazy(() => import("./pages/food-pattern/FoodPatternSurvey"));
  const OgttPage = lazy(() => import("./pages/ogtt/OgttPage"));
  const OgttResult = lazy(() => import("./pages/ogtt/OgttResult"));
  const OgttSurvey = lazy(() => import("./pages/ogtt/OgttSurvey"));
  const FoodBloodPage = lazy(() => import("./pages/food-blood/FoodBloodPage"));
  const ResultSummary = lazy(() => import("./pages/food-blood/ResultSummary"));
  const FoodBloodSurvey = lazy(() => import("./pages/food-blood/FoodBloodSurvey"));
  const SurveySuccess = lazy(() => import("./components/SurveySuccess"));
  const SurveyListPage = lazy(() => import("./pages/survey/SurveyListPage"));
  const Withdrawal = lazy(() => import("./pages/Withdrawal"));

  const NoticePage = lazy(() => import("./pages/notice/NoticePage"));
  const NhisListPage = lazy(() => import("./pages/nhis-list/NhisListPage"));
  const WalkStatePage = lazy(() => import("./pages/walk-state/WalkStatePage"));

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/main" />
        }
        <ContentRoute path="/main" component={auth.level === 900 ? MainPage : UserMainPage} />
        <ContentRoute path="/userlist" component={UserListPage} />
        <ContentRoute path="/predict-input" component={PredictPage} />
        <ContentRoute path="/predict-history" component={PredictHistoryPage} />
        <ContentRoute path="/predict-result" component={PredictResultPage} />
        <ContentRoute path="/predict-survey" component={PredictSurvey} />
        <ContentRoute path="/foodpattern-input" component={FoodPatternPage} />
        <ContentRoute path="/foodpattern-result" component={FoodPatternResult} />
        <ContentRoute path="/foodpattern-survey" component={FoodPatternSurvey} />
        <ContentRoute path="/ogtt-input" component={OgttPage} />
        <ContentRoute path="/ogtt-result" component={OgttResult} />
        <ContentRoute path="/ogtt-survey" component={OgttSurvey} />
        <ContentRoute path="/foodblood-list" component={FoodBloodPage} />
        <ContentRoute path="/foodblood-result" component={ResultSummary} />
        <ContentRoute path="/foodblood-survey" component={FoodBloodSurvey} />
        <ContentRoute path="/survey-complete" component={SurveySuccess} />
        <ContentRoute path="/survey-list" component={SurveyListPage} />
        <ContentRoute path="/crf/list" component={CaseReportList} />
        <ContentRoute path="/crf/create" component={CaseReportForm} />
        <ContentRoute path="/crf/edit/:id" component={CaseReportForm} />

        <ContentRoute path="/nhis-list" component={NhisListPage} />
        <ContentRoute path="/notice" component={NoticePage} />
        <ContentRoute path="/walk-state" component={WalkStatePage} />

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
